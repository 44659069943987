import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, InputAdornment, IconButton, OutlinedInput, InputLabel, FormControl, Alert, Backdrop, CircularProgress } from '@mui/material';
import { Email, Visibility, VisibilityOff } from '@mui/icons-material';
import { BlueRectangle, LoginContainer, LoginForm } from './utils/LoginUtils.js';
import { loginMurciaSSO, getUserId, validateUser, validateUserWithPassword, createUser, assignOrganism, assignPwd } from '../_endpoints_/_api/api.js';
import { User } from '../datamodels/User.js';
import { getTranslationsForLanguage } from './utils/InitializeTranslations.js';
import { getLoginUrl } from '../_services/_hostingenvironments_/hostingEnvironments.js';

const Login: React.FC = () => {
    const userLanguage = navigator.language.split('-')[0]; // Obtener idioma principal del navegador
    const translations = getTranslationsForLanguage(userLanguage);
  
    const [user, setUser] = useState<User>({
        mail: '',
        password: '',
    });

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [token, setToken] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleChange = (prop: keyof User) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [prop]: event.target.value });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        
        try {
            // Verifiquem si l'usuari ja existeix a la base de dades de murcia
            const response = await loginMurciaSSO(user.mail, user.password);

            if(response != null) {
                // Si existeix l'user, comprovem si ja està a la nostra base de dades i agafem el seu id
                const responseUserId = await getUserId(user.mail);
                if(responseUserId.response != null && responseUserId.response.status === 200) {
                    const userId = responseUserId.response.data.userid;
                    // Validem l'usuari amb el seu id
                    const responseCheckUser = await validateUser(userId);
                    if(responseCheckUser.response != null && responseCheckUser.response.status === 200) {
                        // Si l'usuari ja existeix, comprovem si la contrasenya coincideix 
                        const responseUserPwd = await validateUserWithPassword(user.mail, user.password);
                        if(responseUserPwd.response != null && responseUserPwd.response.status === 200) {
                            // Si la contrasenya conicideix, agafem token
                            setToken(responseUserPwd.response.data.token);
                        }
                        if(responseUserPwd.response === null) {
                            // Si la contrasenya no coincideix, assignem la nova contrasenya
                            const pwd = await assignPwd(userId, user.password);
                            if(pwd != null) {
                                // Si no hi ha error en el canvi de contrasenya, validem l'usuari amb la nova contrasenya
                                const responseUserPwd = await validateUserWithPassword(user.mail, user.password);
                                if(responseUserPwd.response != null && responseUserPwd.response.status === 200) {
                                    // Si no hi ha error, agafem token
                                    setToken(responseUserPwd.response.data.token);
                                }                           
                            }
                        }
                    } 
                } else {
                    // Si no existeix l'user, el creem
                    const newUser = await createUser(user.mail, user.password);
                    if(newUser != null) {
                        // Si es crea correctament, agafem el seu id
                        const responseUserId = await getUserId(user.mail);
                        const userId = responseUserId.response.data.userid;
                        if(responseUserId.response.status === 200) {
                            // Assignem l'organisme a l'usuari
                            const assignOrg = await assignOrganism(userId);
                            if(assignOrg != null) {
                                // Si s'assigna correctament, validem l'usuari amb la contrasenya
                                const responseUserPwd = await validateUserWithPassword(user.mail, user.password);
                                if(responseUserPwd.response.status === 200) {
                                    // Si no hi ha error, agafem token
                                    setToken(responseUserPwd.response.data.token);
                                }    
                            }
                        }
                    } 
                }
            }
 
            setShowPassword(false);
            setErrorMessage('');  // Clear any previous error messages
            
        } catch (error) {
            setErrorMessage(translations.login_error);
        } finally {
            setLoading(false);
        }
    };
 
    useEffect(() => {
        const redirectIfNeeded = () => {
            if (token !== '') {
                const url = getLoginUrl() + 'configuration?access_token=' + token;
                window.location.href = url;
            }
        };    
        redirectIfNeeded()
    }, [token]);

    return (
        <LoginContainer>
            <Backdrop open={loading} style={{ zIndex: 10000, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <BlueRectangle> 
                <img src={require('../assets/u-admin.png')} alt="U-Admin" style={{ height: '50px' }} />
            </BlueRectangle>
            <Box width="30%" mt={2} mb={2} borderRadius={0}>
                <LoginForm elevation={3}>
                    <Typography variant="body2" align="center" gutterBottom>
                        {translations.sign_in}
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Box mb={2} mt={2}>
                            <TextField
                                fullWidth
                                label={translations.email}
                                variant="outlined"
                                id="outlined-required"
                                required
                                value={user.mail}
                                onChange={handleChange('mail')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Email />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box mb={2}>
                            <FormControl sx={{ width:'100%' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">{translations.password}</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    required
                                    value={user.password}
                                    onChange={handleChange('password')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={translations.password}
                                />
                            </FormControl>
                        </Box>
                        {errorMessage && (
                            <Box mb={2}>
                                <Alert severity="error">{errorMessage}</Alert>
                            </Box>
                        )}
                        <Box textAlign="center">
                            <Button
                                variant="contained"
                                style={{ backgroundColor: 'rgba(0, 192, 239, 1)', color: 'white',padding: '8px 64px' }}
                                type="submit"
                            >
                                {translations.enter}
                            </Button>
                        </Box>
                    </form>
                </LoginForm>
            </Box>
        </LoginContainer>
    );
};

export default Login;
