import React from 'react';
import '../styles/App.css';
import Login from './Login.tsx';

function App() {
  return (
    <div className="App">
      <Login />
    </div>
  );
}

export default App;
