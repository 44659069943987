import englishTranslation from "../../_translations_/en.global.json";
import frenchTranslation from "../../_translations_/fr.global.json";
import catalanTranslation from "../../_translations_/ca.global.json";
import spanishTranslation from "../../_translations_/es.global.json";
import lithuanianTranslation from "../../_translations_/lt.global.json";
import italianTranslation from "../../_translations_/it.global.json";
import portugueseTranslation from "../../_translations_/pt.global.json";
import basqueTranslation from "../../_translations_/eu.global.json";

const translations = {
  en: englishTranslation,
  es: spanishTranslation,
  fr: frenchTranslation,
  ca: catalanTranslation,
  lt: lithuanianTranslation,
  it: italianTranslation,
  pt: portugueseTranslation,
  eu: basqueTranslation
};

export const getTranslationsForLanguage = (languageCode) => {
  return translations[languageCode] || englishTranslation; // Por defecto, inglés si no se encuentra el idioma
};