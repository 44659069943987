import { styled } from '@mui/system';
import { Box, Paper } from '@mui/material';

export const BlueRectangle = styled(Box)(({ theme }) => ({
    width: '30%',
    height: 50,
    backgroundColor: 'rgba(0, 192, 239, 1)',
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const LoginContainer = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${require('../../assets/background.jpg')})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '40vh'
}));

export const LoginForm = styled(Paper)(({ theme }) => ({
    padding: 20,
    backgroundColor: 'rgba(255, 255, 255, 1)',
}));

