export function getBaseUrl() {
    return window.ENV_CONFIG.URL_API_INTERNA;
  }

export function getLoginUrl() {
    return window.ENV_CONFIG.URL_LOGIN;
  }

export function getAccessToken() {
    return window.ACCESS_TOKEN_KEY.ACCESS_TOKEN_KEY;
  }

export function getServerSOO() {
  return window.SERVER_SSO.URL_SERVER_SSO;
}

export function getOrganismId() {
  return window.ENV_CONFIG.ORGANISM_ID;
}