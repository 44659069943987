import { getAccessToken } from "../_hostingenvironments_/hostingEnvironments";
import { axiosRequestTypes } from "../axiosRequestTypes";

export function getConfig(cancelToken) {
  const accessToken = getAccessToken();
  return {
    headers: { IDENTITY_KEY: accessToken },
    cancelToken: cancelToken,
  };
}

export const ApiConnection = new axiosRequestTypes(getConfig);
