import axios from 'axios';

export const cancelRequestToApiMessage = "The request was canceled";
export function isCancelRequestToApi(error){
    return axios.isCancel(error);
}

function isUNAUTHORIZED(error){
    return error.response.data.reason === "Unauthorized";
}

function errorHandler(error){
    const CancelRequestToApi = isCancelRequestToApi(error);
    if(CancelRequestToApi) {
        return Promise.reject(error);
    }

    const UNAUTHORIZED = isUNAUTHORIZED(error);
    if(UNAUTHORIZED) {
        const isLocalHost = window.location.hostname === 'localhost';
        if(isLocalHost) {
            console.log("Axios! si no fos localhost hauriem anat al loginPage")
            return Promise.reject(error);
        }
    }

    return Promise.reject(error);
}

axios.interceptors.response.use(
    response => response,
    error => errorHandler(error)
);

export default axios;

export function getCancelTokenSource(){
    return axios.CancelToken.source();
}