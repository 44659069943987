import {
  getBaseUrl,
  getOrganismId,
  getServerSOO,
  getAccessToken
} from "../../_services/_hostingenvironments_/hostingEnvironments";
import { ApiConnection } from "../../_services/connections/api";
import axios from "axios";

export const loginMurciaSSO = async (email, password) => {
  const source = axios.CancelToken.source();
  try {
    let url = getServerSOO();
    url += "/v3/auth/tokens";
    console.log(url);

    const body = {
      auth: {
        identity: {
          methods: ["password"],
          password: {
            user: {
              domain: {
                name: "murcia",
              },
              name: email, // Inserta el nombre de usuario
              password: password, // Inserta la contraseña
            },
          },
        },
      },
      scope: {
        domain: {
          name: "murcia",
        },
      },
    };

    const response = await axios.post(url, body, {
      cancelToken: source.token,
    });

    console.log("Respuesta:", response.data);

    const data = await response.data;
    return data;
  } catch (error) {
    console.error("Error en la llamada a la API:", error);
    throw error;
  } finally {
    // Cancela la solicitud si es necesario
    source.cancel("Request completed or component unmounted");
  }
};

export const getUserId = async (email) => {
  const source = axios.CancelToken.source();
    let url = getBaseUrl(); 
    url += "v6/uadmin/userid/" + email;

    console.log("URL userid:", url);

    const response = await ApiConnection.getErrorAndResponse(
      url,
      source.token
    );
    return response;
};

export const validateUser = async (userId) => {
  const source = axios.CancelToken.source();
  let url = getBaseUrl();
  url += "v6/uadmin/users/" + userId;
  console.log("URL validateuser:", url);

  const response = await ApiConnection.getErrorAndResponse(url, source.token);
  return response;
};

export const validateUserWithPassword = async (email, password) => {
  const source = axios.CancelToken.source();
  let url = getBaseUrl();
  url += "/v6/uadmin/auth/" + email + '/' + password;
  console.log("URL validateuser with pwd:", url);

  const response = await ApiConnection.getErrorAndResponse(url, source.token);
  return response;
};

export const createUser = async (email, password) => {
  const source = axios.CancelToken.source();

    let url = getBaseUrl();
    let organismid = getOrganismId();
    let IDENTITY_KEY = getAccessToken();
    url += "v6/uadmin/users?organismid=" + organismid + "&emailsend=false";
    console.log("URL create:", url);

    const atIndex = email.indexOf('@');
    const name = atIndex !== -1 ? email.slice(0, atIndex) : email;

    const body = {
      "name":name,
      "email":email,
      "password":password, 
      "language":"es"
    };
    const response = await axios.post(url, body, {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/json',
            'IDENTITY_KEY': IDENTITY_KEY
          }
        });    

        console.log("Respuesta:", response);

        return response ;
 
};

export const assignPwd = async (userId, password) => {
  const source = axios.CancelToken.source();

    let url = getBaseUrl();
    let IDENTITY_KEY = getAccessToken();
    url += "v6/uadmin/users/" + userId + "?emailsend=false";
    console.log("URL assign pwd:", url);

    const body = {
      "password":password, 
    };
    const response = await axios.put(url, body, {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/json',
            'IDENTITY_KEY': IDENTITY_KEY
          }
        });    

        console.log("Respuesta:", response);

    return response ;
};

export const assignOrganism = async (userId) => {
  const source = axios.CancelToken.source();
    let url = getBaseUrl();
    let IDENTITY_KEY = getAccessToken();
    let organismid = getOrganismId();
    url += "v6/uadmin/organisms/roles/" + userId;

    const body = {
      "2":{
        "organismids":[
             organismid
        ]
      }
    };

    const response = await axios.put(url, body, {
      cancelToken: source.token,
      headers: {
        'Content-Type': 'application/json',
        'IDENTITY_KEY': IDENTITY_KEY
      }
    });

    console.log("Respuesta:", response.data);

    const data = await response.data;
    return data;

};