import axios from './axios';

export function axiosRequestTypes(getConfig) {
    function thenCatch(request){
        return request
        .then(response => ({error: null, response}))
        .catch(error => ({error, response: null}));
    }

    function getErrorAndResponse(url, cancelToken) {
        const config = getConfig(cancelToken);
        return thenCatch(axios.get(url, config));
    }

    return {
        getErrorAndResponse,
    }
}
